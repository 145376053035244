const data = {
    archives: [
        {
          id: 1,
          title: 'Waves in Blue',
          description: "colored pencil and graphite pencil on paper",
          size: "19 x 24 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_rgb_b.jpg"),
        },
        {
          id: 2,
          title: 'Waves in Green',
          description: "colored pencil and graphite pencil on paper",
          size: "19 x 24 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_rgb_g.jpg"),
        },
        {
          id: 3,
          title: 'Waves in Red',
          description: "colored pencil and graphite pencil on paper",
          size: "19 x 24 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_rgb_r.jpg"),
        },
        {
          id: 4,
          title: 'Waves in Black No. 1',
          description: "graphite pencil on paper",
          size: "22 x 30 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_black_1.jpg"),
        },
        {
          id: 5,
          title: 'Waves in Black No. 2',
          description: "graphite pencil on paper",
          size: "22 x 30 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_black_2.jpg"),
        },
        {
          id: 6,
          title: 'Mass in RGB',
          description: "colored pencil and graphite pencil on paper",
          size: "22 x 30 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_rgb.jpg"),
        },
        {
          id: 7,
          title: 'Mass in Blue and Orange',
          description: "colored pencil and graphite pencil on paper",
          size: "22 x 22 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_blue-orange.jpg"),
        },        {
          id: 8,
          title: 'Mass in Green',
          description: "colored pencil and graphite pencil on paper",
          size: "18 x 18 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_green.jpg"),
        },
        {
          id: 9,
          title: 'Waves in Full Color No. 1',
          description: "colored pencil on paper",
          size: "11 x 14 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_full-color_1.jpg"),
        },
        {
          id: 10,
          title: 'Waves in Full Color No. 2',
          description: "colored pencil on paper",
          size: "14 x 17 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_full-color_2.jpg"),
        },
        {
          id: 11,
          title: 'Waves in Full Color No. 3',
          description: "colored pencil on paper",
          size: "16 x 20 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_full-color_3.jpg"),
        },
        {
          id: 12,
          title: 'Mass in Full Color No. 1',
          description: "colored pencil on paper",
          size: "11 x 14 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_full-color-1.jpg"),
        },
        {
          id: 13,
          title: 'Mass in Full Color No. 2',
          description: "colored pencil on paper",
          size: "14 x 17 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_full-color-2.jpg"),
        },
        {
          id: 14,
          title: 'Mass in Full Color No. 3',
          description: "colored pencil on paper",
          size: "22 x 30 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_full-color-3.jpg"),
        },
        {
          id: 15,
          title: 'Mass in Black and Yellow',
          description: "colored pencil and graphite pencil on paper",
          size: "18 x 18 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_black-yellow.jpg"),
        },
        {
          id: 16,
          title: 'Mass in White',
          description: "graphite pencil on paper",
          size: "14 x 17 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_white.jpg"),
        },
        {
          id: 17,
          title: 'Mass in Black',
          description: "graphite powder on paper",
          size: "14 x 17 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_black.jpg"),
        },
        {
          id: 18,
          title: 'Mass in Black and White No. 1',
          description: "graphite pencil on paper",
          size: "14 x 17 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_black-white_1.jpg"),
        },
        {
          id: 19,
          title: 'Mass in Black and White No. 2',
          description: "graphite pencil on paper",
          size: "19 x 24 inches",
          year: "Year 01",
          image: require("./../img/archive/mass_black-white_2.jpg"),
        },
        {
          id: 20,
          title: 'Waves in Black and White No. 1',
          description: "graphite pencil on paper",
          size: "11 x 14 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_black-white_1.jpg"),
        },
        {
          id: 21,
          title: 'Waves in Black and White No. 2',
          description: "graphite pencil on paper",
          size: "14 x 17 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_black-white_2.jpg"),
        },
        {
          id: 22,
          title: 'Waves in Black and White No. 3',
          description: "graphite pencil on paper",
          size: "11 x 14 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_black-white_3.jpg"),
        },
        {
          id: 23,
          title: 'Waves in Black and White No. 4',
          description: "graphite pencil on paper",
          size: "14 x 17 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_black-white_4.jpg"),
        },
        {
          id: 24,
          title: 'Waves in Black and White No. 5',
          description: "graphite pencil on paper",
          size: "11 x 14 inches",
          year: "Year 01",
          image: require("./../img/archive/waves_black-white_5.jpg"),
        },
    ],
  };
  
  export default data;