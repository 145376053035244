const data = {
    collaborations: [
      {
        id: 1,
        title: 'Little Pigeon',
        description: "clay, tablet, hd video + audio",
        size: "24 x 19 x 8 inches",
        year: "with Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/little-pigeon_2.jpg"),
        link: "https://www.youtube.com/watch?v=xSSyWCnXYGs", 
      },
      {
        id: 2,
        title: 'Little Pigeon',
        description: "clay, tablet, hd video + audio",
        size: "24 x 19 x 8 inches",
        year: "with Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/little-pigeon_1.jpg"),
        link: "https://www.youtube.com/watch?v=xSSyWCnXYGs",       
      },
      {
        id: 3,
        title: 'Little Pigeon',
        description: "clay, tablet, hd video + audio",
        size: "24 x 19 x 8 inches",
        year: "with Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/little-pigeon_3.jpg"),
        link: "https://www.youtube.com/watch?v=xSSyWCnXYGs",       
      },
      {
        id: 4,
        title: 'Mooning Nancy',
        description: "spellcasting salt, acrylic, hd video on monitor",
        size: "dimensions variable",
        year: "with Michael Stephen, Jessi DiTillio, Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/mooning-nancy_1.jpg"),
        link: "https://www.youtube.com/watch?v=kkc1VzzU8Os&t=239s",       
      },
      {
        id: 5,
        title: 'Mooning Nancy',
        description: "spellcasting salt, acrylic, hd video on monitor",
        size: "dimensions variable",
        year: "with Michael Stephen, Jessi DiTillio, Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/mooning-nancy_3.jpg"),
        link: "https://www.youtube.com/watch?v=kkc1VzzU8Os&t=239s",       
      },
      {
        id: 6,
        title: 'Spellcasting salt',
        description: "various magical ingredients collected under the solstice full moon",
        size: "2 oz",
        year: "with Michael Stephen, Jessi DiTillio, Katherine Spinella",
        image: require("./../img/collaboration/mooning-nancy_4.jpg"),   
      },
      {
        id: 7,
        title: 'C-Curve Viewfinder',
        description: "clay, raspberry pi, hd video, wood, oil, power cord",
        size: "10 x 10 x 36 inches",
        year: "with Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/c-curve_5.jpg"),
        link: "https://www.youtube.com/watch?v=cRUDaQ3zgDU",       
      },
      {
        id: 8,
        title: 'C-Curve Viewfinder',
        description: "clay, raspberry pi, hd video, wood, oil, power cord",
        size: "10 x 10 x 36 inches",
        year: "with Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/c-curve_6.jpg"),
        link: "https://www.youtube.com/watch?v=cRUDaQ3zgDU",       
      },
      {
        id: 9,
        title: 'C-Curve',
        description: "memory foam, wood, plastic, hd video projection",
        size: "dimensions variable",
        year: "with Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/c-curve_2.jpg"),
        link: "https://www.youtube.com/watch?v=rO0dYrS-Fbk",       
      },
      {
        id: 10,
        title: 'C-Curve',
        description: "memory foam, wood, plastic, hd video projection",
        size: "dimensions variable",
        year: "with Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/c-curve_3.jpg"),
        link: "https://www.youtube.com/watch?v=rO0dYrS-Fbk",       
      },
      {
        id: 11,
        title: 'C-Curve',
        description: "memory foam, wood, plastic, hd video projection",
        size: "dimensions variable",
        year: "with Katherine Spinella",
        click: "(click image to view on YouTube)",
        image: require("./../img/collaboration/c-curve_4.jpg"),
        link: "https://www.youtube.com/watch?v=rO0dYrS-Fbk",       
      },
      {
        id: 12,
        title: 'Thunderstruck',
        description: "38 page full-color book, perfect bound, distributed by Printed Matter, Inc.",
        size: "8.5 x 5.5 inches",
        year: "with Michael Stephen, Jessi DiTillio, Katherine Spinella",
        click: "(click image for flipbook – best viewed on desktop)",
        image: require("./../img/collaboration/book.jpg"),
        link: "/collaboration/book",       
      },
    ],
  };
  export default data;
  