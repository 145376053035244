const data = {
  drawings: [
      {
        id: 1,
        title: '42.3516° N, 107.4573° W, at 30,000 ft',
        description: "colored pencil on paper",
        size: "22 x 30 inches",
        year: "2024",
        image: require("./../img/drawings/clouds.jpg"),
      },
      {
        id: 2,
        title: '40.3573° N, 74.6672° W',
        description: "colored pencil on paper",
        size: "22 x 30 inches",
        year: "2024",
        image: require("./../img/drawings/willow.jpg"),
      },
      {
        id: 3,
        title: '44.6498° N, 120.2667° W',
        description: "colored pencil on paper",
        size: "22 x 30 inches",
        year: "2023",
        image: require("./../img/drawings/hills.jpg"),
      },
      {
        id: 4,
        title: '46.2496° N, 122.1369° W',
        description: "graphite pencil on paper",
        size: "22 x 30 inches",
        year: "2022",
        image: require("./../img/drawings/rock.jpg"),
      },
      {
        id: 5,
        title: '46.2956° N, 122.2522° W',
        description: "graphite pencil on paper",
        size: "22 x 30 inches",
        year: "2022",
        image: require("./../img/drawings/water.jpg"),
      },
      {
        id: 6,
        title: '38.1123° N, 109.5311° W',
        description: "graphite pencil on paper",
        size: "22 x 30 inches",
        year: "2022",
        image: require("./../img/drawings/dirt.jpg"),
      },
      {
        id: 7,
        title: 'Spirit Lake Cloud',
        description: "graphite pencil on paper",
        size: "22 x 30 inches",
        year: "2021",
        image: require("./../img/drawings/sl-cloud.jpg"),
      },
      {
        id: 8,
        title: 'Spirit Lake Tarot',
        description: "graphite pencil on paper",
        size: "22 x 30 inches",
        year: "2021",
        image: require("./../img/drawings/tarot.jpg"),
      },
      {
        id: 9,
        title: 'Perseus',
        description: "graphite pencil on paper",
        size: "22 x 30 inches",
        year: "2021",
        image: require("./../img/drawings/perseus.jpg"),
      },
      {
        id: 10,
        title: 'Cabin Interior',
        description: "graphite pencil on paper",
        size: "22 x 30 inches",
        year: "2020",
        image: require("./../img/drawings/cabin-interior.jpg"),
      },
      {
        id: 11,
        title: 'Google Map',
        description: "graphite pencil on paper",
        size: "22 x 30 inches",
        year: "2020",
        image: require("./../img/drawings/google-map.jpg"),
      },
      {
        id: 12,
        title: 'Supernova',
        description: "graphite pencil on paper",
        size: "22 x 30 inches",
        year: "2020",
        image: require("./../img/drawings/supernova.jpg"),
      },
      {
        id: 13,
        title: 'Lunar Eclipse',
        description: "colored pencil pencil on paper",
        size: "12 x 18 inches",
        year: "2023",
        image: require("./../img/drawings/eclipse.jpg"),
      },
  ],
};

export default data;